exports.components = {
  "component---src-components-articles-article-post-tsx": () => import("./../../../src/components/articles/ArticlePost.tsx" /* webpackChunkName: "component---src-components-articles-article-post-tsx" */),
  "component---src-components-articles-articles-tsx": () => import("./../../../src/components/articles/Articles.tsx" /* webpackChunkName: "component---src-components-articles-articles-tsx" */),
  "component---src-components-catalog-component-preview-cards-section-tsx": () => import("./../../../src/components/catalog/ComponentPreviewCardsSection.tsx" /* webpackChunkName: "component---src-components-catalog-component-preview-cards-section-tsx" */),
  "component---src-components-core-sitemap-tsx": () => import("./../../../src/components/core/Sitemap.tsx" /* webpackChunkName: "component---src-components-core-sitemap-tsx" */),
  "component---src-components-ifttt-if-this-then-that-tsx": () => import("./../../../src/components/ifttt/IfThisThenThat.tsx" /* webpackChunkName: "component---src-components-ifttt-if-this-then-that-tsx" */),
  "component---src-components-impact-impact-page-tsx": () => import("./../../../src/components/impact/ImpactPage.tsx" /* webpackChunkName: "component---src-components-impact-impact-page-tsx" */),
  "component---src-components-landing-landing-screen-tsx": () => import("./../../../src/components/landing/LandingScreen.tsx" /* webpackChunkName: "component---src-components-landing-landing-screen-tsx" */),
  "component---src-components-newsletter-subscribe-page-tsx": () => import("./../../../src/components/newsletter/SubscribePage.tsx" /* webpackChunkName: "component---src-components-newsletter-subscribe-page-tsx" */),
  "component---src-components-quickbites-quick-bites-tsx": () => import("./../../../src/components/quickbites/QuickBites.tsx" /* webpackChunkName: "component---src-components-quickbites-quick-bites-tsx" */),
  "component---src-components-snippets-snippet-post-tsx": () => import("./../../../src/components/snippets/SnippetPost.tsx" /* webpackChunkName: "component---src-components-snippets-snippet-post-tsx" */),
  "component---src-components-snippets-snippets-tsx": () => import("./../../../src/components/snippets/Snippets.tsx" /* webpackChunkName: "component---src-components-snippets-snippets-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-tailwind-components-declarative-mapper-page-tsx": () => import("./../../../src/tailwindComponents/DeclarativeMapperPage.tsx" /* webpackChunkName: "component---src-tailwind-components-declarative-mapper-page-tsx" */),
  "component---src-tailwind-components-faq-tsx": () => import("./../../../src/tailwindComponents/FAQ.tsx" /* webpackChunkName: "component---src-tailwind-components-faq-tsx" */),
  "component---src-tailwind-components-legal-page-tsx": () => import("./../../../src/tailwindComponents/LegalPage.tsx" /* webpackChunkName: "component---src-tailwind-components-legal-page-tsx" */),
  "component---src-tailwind-components-newsletters-tsx": () => import("./../../../src/tailwindComponents/Newsletters.tsx" /* webpackChunkName: "component---src-tailwind-components-newsletters-tsx" */)
}

