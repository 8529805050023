import React, { useEffect, useState } from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { StaticImage } from "gatsby-plugin-image";

interface InlineAffiliateBannerProps {
  companyName: string;
  headline: string;
  description?: string;
  ctaText: string;
  ctaUrl: string;
  bgColorClass?: string;
  textColorClass?: string;
  buttonColorClass?: string;
  includeEndorsement?: boolean;
  secondaryCta?: {
    text: string;
    url: string;
  };
  accentBorder?: boolean;
}

export default function InlineAffiliateBanner(
  props: InlineAffiliateBannerProps
) {
  const [currentUrl, setCurrentUrl] = useState<string>("");

  // Set the current URL when the component mounts
  useEffect(() => {
    if (typeof window !== "undefined") {
      setCurrentUrl(window.location.href);
    }
  }, []);

  // Unified tracking function for all banner interactions
  const trackBannerInteraction = (interactionType: string = "banner_click") => {
    if (window && !window.location.href.includes("localhost")) {
      // GA4 event format
      window.gtag("event", "inline_affiliate_interaction", {
        affiliate_company: props.companyName,
        source_page: currentUrl || window.location.href,
        page_title: document.title,
        banner_headline: props.headline,
        banner_type: "inline",
        interaction_type: interactionType,
        cta_text: interactionType.includes("primary")
          ? props.ctaText
          : interactionType.includes("secondary")
          ? props.secondaryCta?.text
          : undefined,
      });
    }
  };

  // Default values for props
  const textColorClass = props.textColorClass || "text-gray-900";
  const buttonColorClass =
    props.buttonColorClass || "bg-indigo-600 hover:bg-indigo-700";
  const includeEndorsement = props.includeEndorsement ?? true;

  // Personal endorsement text
  const endorsementText =
    "I personally used this resource to prepare for my tech interviews and landed offers at all 8 companies I interviewed with, including FAANG. Highly recommended!";

  return (
    <div className="my-12 w-full mx-auto max-w-xl">
      <OutboundLink
        href={props.ctaUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => trackBannerInteraction("banner_click")}
        className="block no-underline"
      >
        <div
          className={`rounded-xl overflow-hidden border border-gray-200 ${
            props.bgColorClass || "bg-indigo-50"
          } hover:border-indigo-200 transition-colors duration-200`}
        >
          {/* Card Container */}
          <div className="p-8 md:p-10 flex flex-col">
            {/* Logo at the top */}
            <div className="mb-5">
              <div className="w-40">
                <StaticImage
                  src="../images/educative_logo_horizontal.png"
                  alt={props.companyName}
                  width={200}
                  placeholder="none"
                  className="flex-shrink-0"
                  style={{ margin: 0 }}
                />
              </div>
            </div>

            {/* Content Section */}
            <div className="w-full">
              {/* Headline */}
              <div
                className={`text-3xl md:text-4xl font-extrabold ${textColorClass} mb-4 font-inter leading-tight tracking-tight`}
              >
                {props.headline}
              </div>

              {props.description && (
                <div className="text-gray-700 text-lg mb-5 font-inter leading-relaxed max-w-4xl">
                  {props.description}
                </div>
              )}

              {/* Personal Endorsement */}
              {includeEndorsement && (
                <div className="mb-6 text-gray-800 bg-white p-4 rounded-lg border-l-4 border-indigo-400 shadow-sm">
                  <p className="text-lg font-inter">"{endorsementText}"</p>
                  <p className="text-sm font-medium mt-2 font-inter text-indigo-800">
                    — Vinay Gaba
                  </p>
                </div>
              )}

              {/* CTA Button(s) */}
              <div className="mt-6 flex flex-col sm:flex-row gap-4">
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the parent link
                    trackBannerInteraction("primary_cta_click");
                  }}
                  className={`text-center ${buttonColorClass} !text-white font-bold py-3.5 px-8 rounded-md transition-all duration-200 hover:bg-indigo-700 font-inter text-base w-full`}
                >
                  {props.ctaText}
                </button>

                {props.secondaryCta && (
                  <OutboundLink
                    href={props.secondaryCta.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the parent link
                      trackBannerInteraction("secondary_cta_click");
                    }}
                    className="text-center text-gray-700 font-medium py-3.5 px-6 rounded-md border border-gray-300 hover:bg-gray-100 hover:border-gray-400 transition-all duration-200 font-inter no-underline"
                    style={{ textDecoration: "none !important" }}
                  >
                    {props.secondaryCta.text}
                  </OutboundLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </OutboundLink>
    </div>
  );
}
