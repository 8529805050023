import "./defaultStyle.css";
import "./src/styles/global.css";

// https://stackoverflow.com/questions/55336831/how-to-fix-gatsby-js-link-component-retaining-scroll-position-and-not-resetting
export function shouldUpdateScroll({
  routerProps: props,
  prevRouterProps: prevProps,
  getSavedScrollPosition,
}) {
  const { pathname, href } = props.location;
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [
    `/articles`,
    `/newsletter`,
    `/compose-catalog`,
    `/faq`,
    `/quick-bites`,
    `/What-is-the-equivalent`,
    `/sitemap`,
    `/impact`,
    `/snippets`,
    `/compare-declarative-frameworks`,
    `libraries-in-Jetpack-Compose`,
    `/privacy-policy`,
  ];
  // if the new route is part of the list above, scroll to top (0, 0)
  let skipPreviousCheck = false;
  if (!prevProps) {
    skipPreviousCheck = true;
  }

  if (
    pathMatched(scrollToTopRoutes, pathname) &&
    location.href.indexOf("#") === -1 &&
    (skipPreviousCheck || prevProps.location.href.indexOf("#") === -1) // handle the case where articles have an anchor link for headings
  ) {
    window.scrollTo(0, 0);
  }

  return false;
}

// scrollToTopRoutes.findIndex((route) => pathname.indexOf(route) !== -1) !== -1

function pathMatched(scrollToTopRoutes, pathname) {
  if (pathname === "/") {
    return true;
  }
  let result = false;
  scrollToTopRoutes.forEach((route) => {
    if (pathname.indexOf(route) !== -1) {
      result = true;
    }
  });
  return result;
}
